
    //NON SCOPED STYLE for menu item slot

    .top-menu {
        --menu-item-padding: calc(var(--global-margin) * 1);

        .main-menu-items {


            li {
                a {

                    position: relative;
                    font-size: calc(var(--global-font-size) * 1.3);
                    padding: 4px var(--menu-item-padding);
                    font-weight: 200;
                    transition: color 175ms ease-in-out;
                    cursor: pointer;

                    &::after {
                        content: ' ';
                        z-index: 2;
                        position: absolute;
                        bottom: 0;
                        height: 2px;
                        left: 0;
                        width: 0;
                        background-color: var(--global-primary-background);
                        transition: width 200ms ease-out;
                        will-change: width;

                    }
                }
            }

            li.active a,
            li.uk-active a,
            li a.active,
            li a.uk-active,
            li a:hover {
                color: var(--global-primary-background);
            }

            li a:hover {

                &::after {
                    width: 100%;
                }
            }

            .uk-dropdown-nav {
                padding: calc(var(--global-margin) * 1.1) (calc(var(--global-margin) * 1));
                li {

                    a {
                        color: var(--global-color);
                        display: block;
                        min-width: 250px;
                        padding: calc(var(--global-margin) * 0.4) 0;
                        font-size: calc(var(--global-font-size) * 1.1);
                    }
                }
            }
        }

        .side-menu-items {
            display: block;
            position: relative;
            z-index: 1;
            padding-top: calc(var(--global-margin) * 0.25);

            li {
                display: block;
                margin-top: calc(var(--global-margin) * 0.25);
                a {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    text-align: start;
                    min-height: 0;
                    height: auto;
                    padding: calc(var(--global-margin) * 0.6) calc(var(--global-margin));
                    font-size: calc(var(--global-font-size) * 1.2);
                    background-color: var(--global-muted-background);
                    color: var(--global-secondary-color);

                }
            }
        }

        .side-menu-items.side-menu-items--extra {
            li {
                a {
                    .item-icon {
                        color: var(--global-primary-background);
                    }

                    &.uk-active, &.active, &:hover {
                        background: var(--global-primary-background);
                        color: var(--global-inverse-color);

                        .item-icon {
                            color: var(--global-inverse-color);
                        }
                    }
                }
            }
        }
    }
